export default {
  name: "EmptyData",
  props: {
    screenHeight: {
      type: Number,
    },
  },
  data() {
    return {
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.emptyDataTexts[this.selectLanguage];
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.emptyDataTexts[this.selectLanguage];
      }
    },
  },
};